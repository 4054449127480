import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import { Container } from '../components/Container';

const Unauthorized: React.FC = () => (
  <Container>
    <h1>
      <FormattedMessage id="unaouthorized.title" />
    </h1>

    <Link to="/">
      <FormattedMessage id="unaouthorized.link" />
    </Link>
  </Container>
);

export default Unauthorized;
